import {UPDATE_CUSTOM_FORM_DATA} from '../mutationTypes';
import authStore from '@/store/auth/auth';
import routers, {NO_BASIC_PASS_PATHS} from '@/routers';
import {GET_BASIC_PASS_SELECTED} from '@/store/getterTypes';
import {PACKAGES} from '@/pages';
import {BASIC_PASS_AVAILABLE} from '@/const';

export default {
  [UPDATE_CUSTOM_FORM_DATA](state, {data, alias, getters}) {
    if (data && data.data && alias) {
      state.customForm = {...state.customForm, [alias]: data.data};
    }
    if (!BASIC_PASS_AVAILABLE) return;

    const isLogged = Boolean(authStore.state.user);
    const isBaseSelected = getters[GET_BASIC_PASS_SELECTED];
    if (isLogged && !isBaseSelected) {
      !NO_BASIC_PASS_PATHS.includes(routers.currentRoute.path) && routers.replace(PACKAGES);
    }
  },
};
